const debugar = false ; 
//const URL = 'https://beta.ges.services'
//const URL ='http://localhost:3000'
const URL = 'https://ubiku.ges.services'

const arranque = '/home'
console.log('en la app nueva')
/** 
 * Mecanismo de inclusion de rutas de grabacion  filtradas por tipo de usuario  
 * */

const rutas_capadas = new RegExp(/^\/(xxxxxx)/ig)
//const rutas_geoloc = new RegExp(/^\/(eliminar_ubicacion|actualizar_ubicaciones|set_recepcion|set_entrada|update_envio|update_recepcion)/ig)


    export const myURL= URL;
    import { createApp } from 'vue'
    import App from './App.vue'
    import router from './router';
    import { IonicVue} from '@ionic/vue';
    import { Device } from "@capacitor/device";
    import { openToast} from  '@/utilities/services/alert-utilities';
    import {  openAlertaV2 } from  '@/utilities/services/alert-utilities';
    import { defineCustomElements } from '@ionic/pwa-elements/loader'; 
    import eventBus from "@/event-bus";
    import { globalFilters } from './utilities/filters'
    import { loadStorage,  limpiarMemoria } from '@/utilities/storage/storage-utilities-main'
    import { Camera } from '@capacitor/camera';
    import { Geolocation } from '@capacitor/geolocation';
    import AppTypeahead from "./components/filtros/AppTypeahead.vue";

/* 
  
  Importamos los componente de Ionic que utilizaremos 
  los exponemos desde aqui al resto de la app 
  con un app.compononent... 
 */

import {    
  IonBadge, IonButton, IonButtons, 
  IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCardSubtitle,
  IonCol,
  IonCheckbox,
  IonContent,
  IonFab, IonFabButton,IonFabList, 
  IonGrid,
  IonFooter,
  IonHeader,
  IonIcon,
  IonImg,
  IonInfiniteScroll, IonInfiniteScrollContent,
  IonInput, 
  IonDatetime, 
  IonPicker, 
  IonRadio, IonRadioGroup, 
  IonItem, IonItemDivider, IonItemSliding, IonItemOption, IonItemOptions,
  IonLabel,
  IonList, IonListHeader,
  IonModal,
  IonNav,
  IonNote,
  IonPage,
  IonProgressBar, 
  IonReorder, IonReorderGroup, 
  IonRow,
  IonSearchbar,
  IonSegment, IonSegmentButton,
  IonSelect, IonSelectOption,
  IonSpinner,
  IonTabs, IonTabBar, IonTabButton, 
  IonThumbnail,
  IonText,
  IonTitle,
  IonToggle,
  IonToolbar,

 } from '@ionic/vue';


/** Wijmo components */
  //import { registerCore } from '@grapecity/wijmo.vue2.core';
  import { registerBarcodeCommon } from '@grapecity/wijmo.vue2.barcode.common';
 // apply Wijmo license key (if you have one)  
 import { setLicenseKey } from '@grapecity//wijmo';  
 setLicenseKey('beta.ges.digital|ubiku.ges.digital,288251898349353#B0vyimdiwSZzxWYmpjIyNHZisnOiwmbBJye0ICRiwiI34TQvJmVi36Sp9GZxEFd8EGNPlDUG9kSkRDVE9WcOhGc5x6bzQnTRVHRIdEaXRVT0VnbxU7QENnYxNVOwckcWtSeHB7b5FTRSFlM99mTXdjcXNlcwtSWtd7UEJGboFkdoRlMURzLzl4aWZUWy2WcH3GSYF4biNETPF6MFNVbIdFRvkXV9UVU8JGTMp5ZqBTdzYEOiRDaahTZwZkaQRzN8MDURFnYQl6VhFjWwVDSopGMmR5VkRVTzcUWwQzLDtyRiJVdwI4VXtSUXJzM4djTiVUQ054avAlMBpWcZdHOrhFbCZzR8ljd9kTZEFTM5UHTBl6UZpFa0Fmb5J4RCp4Z58EWyRlU9xWQ0Zma6kUbYVnYxoFdmZncHZVSHhFdDdFekRUWvIFR8t6QONWa7gUMkdWcQ94Uyo5axwkZRN7RPZkSK9WOrgDVNVDcStGaLFXOq3UWTZzcw5kI0IyUiwiIBhDM5YUO6IjI0ICSiwCM9EDO7YTOyQTM0IicfJye&Qf35VfikEMyIlI0IyQiwiIu3Waz9WZ4hXRgACdlVGaThXZsZEIv5mapdlI0IiTisHL3JSNJ9UUiojIDJCLi86bpNnblRHeFBCIyV6dllmV4J7bwVmUg2Wbql6ViojIOJyes4nILdDOIJiOiMkIsIibvl6cuVGd8VEIgc7bSlGdsVXTg2Wbql6ViojIOJyes4nI4YkNEJiOiMkIsIibvl6cuVGd8VEIgAVQM3EIg2Wbql6ViojIOJyes4nIzMEMCJiOiMkIsISZy36Qg2Wbql6ViojIOJyes4nIVhzNBJiOiMkIsIibvl6cuVGd8VEIgQnchh6QsFWaj9WYulmRg2Wbql6ViojIOJyebpjIkJHUiwiI4EzNwUDMgIDM5AzMyAjMiojI4J7QiwiIsFGdpdWak9ycldmL5tWaiVHLsFGdpdWak9ycldmLhRXZiJiOiMXbEJCLiM7aj3GdTBybyR7YlxWRg2Gc5J7RiojIh94QiwiIzUzM9QzM8kDOxUjM8gjMiojIklkIs4XXbpjInxmZiwiIxYnMyAjOimj');  
 

/* Importamos y configuramos axios */
    import axios from 'axios';
    axios.defaults.baseURL = URL;
    axios.defaults.headers["Content-Type"] = "application/json"
    axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
    axios.defaults.headers["Access-Control-Allow-Origin"] = "*"
    axios.mode = "no-cors"
    

/** Version 8.2.6  */
    import firebase from 'firebase/app';
    import 'firebase/auth';


     firebase.initializeApp({
        apiKey: "AIzaSyAzwMsPdcrIbVEvqrqopkjpOLG2Vm3PjGA",
        authDomain: "appgescom.firebaseapp.com",
        projectId: "appgescom",
        storageBucket: "appgescom.appspot.com",
        messagingSenderId: "728244704694",
        appId: "1:728244704694:web:ced2e56799f4abb42a1d17",
        measurementId: "G-KX4GNXBZ0Q",
        databaseURL: "https://appgescom.firebaseio.com"
      });


    /* Core CSS required for Ionic components to work properly */
    import '@ionic/vue/css/core.css';
    /* Basic CSS for apps built with Ionic */
    import '@ionic/vue/css/normalize.css';
    import '@ionic/vue/css/structure.css';
    import '@ionic/vue/css/typography.css';
    /* Optional CSS utils that can be commented out */
    import '@ionic/vue/css/padding.css';
    import '@ionic/vue/css/float-elements.css';
    import '@ionic/vue/css/text-alignment.css';
    import '@ionic/vue/css/text-transformation.css';
    import '@ionic/vue/css/flex-utils.css';
    import '@ionic/vue/css/display.css';

    import 'purecss/build/pure.css';

    /* Theme variables */
    import './theme/variables.css';
    import './registerServiceWorker'
    
    /* Funciones generales */
    window.miconsola =  debugar === true ? console.log : function() {void(0)}
    window.fake_console =  debugar === true ? console.log : function() {void(0)}
    window.fake_console('Paso por MAIN ')
    

/** 
 * Inicializamos la app 
 * 
 * 
 * */

    let app = createApp(App)
          .use(IonicVue)
          .use(router);

          
/* Creamos los componentes globales para que sean accesibles */          
  
  app.component('ion-progress-bar', IonProgressBar);
  app.component('ion-badge', IonBadge);
  app.component('ion-button', IonButton);
  app.component('ion-buttons', IonButtons);
  app.component('ion-card', IonCard);
  app.component('ion-card-content', IonCardContent);
  app.component('ion-card-header', IonCardHeader);
  app.component('ion-card-title', IonCardTitle);
  app.component('ion-card-subtitle', IonCardSubtitle);
  app.component('ion-checkbox', IonCheckbox);
  app.component('ion-col', IonCol);
  app.component('ion-content', IonContent);
  app.component('ion-fab', IonFab);
  app.component('ion-fab-list', IonFabList);
  app.component('ion-fab-button', IonFabButton);
  app.component('ion-grid', IonGrid);
  app.component('ion-footer', IonFooter);
  app.component('ion-header', IonHeader);
  app.component('ion-icon', IonIcon);
  app.component('ion-img', IonImg);
  app.component('ion-input', IonInput);

  app.component('ion-datetime', IonDatetime);
  app.component('ion-picker', IonPicker);

  app.component('ion-infinite-scroll', IonInfiniteScroll);
  app.component('ion-infinite-scroll-content', IonInfiniteScrollContent);
  app.component('ion-item', IonItem);
  app.component('ion-item-divider', IonItemDivider);
  app.component('ion-item-sliding', IonItemSliding); 
  app.component('ion-item-options', IonItemOptions);
  app.component('ion-item-option', IonItemOption);
  app.component('ion-label', IonLabel);
  app.component('ion-list', IonList);
  app.component('ion-list-header', IonListHeader);
  app.component('ion-modal', IonModal);
  app.component('ion-nav', IonNav);
  app.component('ion-note', IonNote);
  app.component('ion-page', IonPage);
  app.component('ion-radio', IonRadio)
  app.component('ion-radio-group', IonRadioGroup)
  app.component('ion-reorder-group', IonReorderGroup)
  app.component('ion-reorder', IonReorder)
  app.component('ion-row', IonRow);
  app.component('ion-searchbar', IonSearchbar);
  app.component('ion-segment', IonSegment);
  app.component('ion-segment-button', IonSegmentButton);
  app.component('ion-select', IonSelect);
  app.component('ion-select-option', IonSelectOption);
  app.component('ion-spinner', IonSpinner);  
  app.component('ion-tabs', IonTabs);
  app.component('ion-tab-bar', IonTabBar);
  app.component('ion-tab-button', IonTabButton);
  app.component('ion-thumbnail', IonThumbnail);
  app.component('ion-text', IonText);
  app.component('ion-title', IonTitle);
  app.component('ion-toggle', IonToggle);
  app.component('ion-toolbar', IonToolbar);

      
  app.component('app-typeahead', AppTypeahead);

/* Añadimos los componentes de wijmo necesarios para uso general */
/** 
    Wijmo Barcodes y Qrs 
    registerBarcodeCommon(app);
    registerCore(app)
    */
    registerBarcodeCommon(app);    


/**
 * Creamos las Variables globales 
 */
    var self = app.config.globalProperties
    self.$http = axios;
    self.$filters = globalFilters
    self.$ruta_imagen =  'https://storage.googleapis.com/mobigescom/';
    self.$home = arranque 
    self.$firebase = firebase
    self.$logged = false 
    



/** Listener para deeplinks */    
eventBus().emitter.on('new-deeplink',(new_deeplink) => {
    self.$deeplink = new_deeplink; 
    window.fake_console('oido cocina : ', self.$deeplink)
})
    


const interceptores = async function(self, be) {
    window.fake_console('Monto los interceptores')
    window.fake_console(self.$pdv , self.$router.currentRoute)
    self.$http.defaults.headers['key'] = be
    self.$http.interceptors.request.use(req => {
        if (rutas_capadas.test(req.url) ) {
            openAlertaV2('No autorizado', 'Peticion no autorizada para tu perfil.... ')
            self.$router.replace('/home')
            return req
        }
        else 
        {
            req.params = req.params || {};
            req.params['dev'] = self.$deviceUuid;
            req.params['ml'] = self.$current_user;
            req.params['wn'] = self.$wnumber ?? '';
            return req; 
        }

    })
    self.$http.interceptors.response.use(
        res=> {
                if (!res.data || res.data.status_log === false ){
                    self.$logged = false;
                    window.fake_console(self.$router.currentRoute, self.$deeplink)
                    self.$router.replace('/login')
                    return false
                }
                else {
                    return res
                }
            }, 
        error => {
            if(error.response.status === 404 ) {
                window.fake_console('Estoy en el error del undefined, pasaremos por login  ')
                self.$logged = false;
                self.$router.replace('/login')
                return false
            }
        }
    )   
    return 1
}




    /** Modelo persistencia  */
    const persist= async function(self) {
        const entroTT = Date.now()
        window.fake_console(`Entro el funcion persist : ${entroTT}`)
        try {
                await limpiarMemoria(self.$pdv)
                //recuperamos los eans 
                window.fake_console(`Inicio recuperacion de datos , llevo  ${Date.now()-entroTT} ms`) 
                openToast('Cargando datos...', `Cargando en memoria los datos del pdv : ${self.$pdv}`, 2500)
                document.getElementById("google-login-btn").setAttribute("disabled", "true");
                document.getElementById("microsoft-login-btn").setAttribute("disabled", "true");
                const eans_pdv = await self.$http.post(`/eans_pdv/${self.$pdv}`)
                window.fake_console(`Eans recuperados, llevo  ${Date.now()-entroTT} ms`)
                window.fake_console(JSON.stringify(eans_pdv.data).slice(0,200))
                const ubicaciones_pdv = await self.$http.post(`/ubicaciones_pdv/${self.$pdv}`)
                window.fake_console(`Ubicaciones recuperados, llevo  ${Date.now()-entroTT} ms`)
                
                self.$ubicaciones_pdv = ubicaciones_pdv.data
                window.fake_console(JSON.stringify(ubicaciones_pdv.data).slice(0,200))
                const catalogo_pdv = await self.$http.post(`/catalogo_pdv/${self.$pdv}`)
                window.fake_console(`Catalogo recuperados, llevo  ${Date.now()-entroTT} ms`)
                window.fake_console(JSON.stringify(catalogo_pdv.data).slice(0,200))
                const lotes_pdv = await self.$http.post(`/lotes_pdv/${self.$pdv}`)
                window.fake_console('Codigos de bobinas recuperados ')
                window.fake_console(JSON.stringify(lotes_pdv.data).slice(0,200))
                const bobinas_pdv = await self.$http.post(`/bobinas/${self.$pdv}`)
                window.fake_console('Codigos de bobinas recuperados ')
                window.fake_console(JSON.stringify(bobinas_pdv.data).slice(0,200))
                const codigos_bobinas = await self.$http.post(`/get_supplier_coils/${self.$pdv}`)
                window.fake_console(JSON.stringify(codigos_bobinas.data).slice(0,200))
                window.fake_console('Nombres de clientes ')
                const clientes_pdv =  await self.$http.post(`/get_clientes_pdv/${self.$pdv}`)
                window.fake_console(JSON.stringify(clientes_pdv.data).slice(0,200))
                //window.fake_console(codigos_bobinas.data)

                window.fake_console(`Lotes (ultimo) recuperados, llevo  ${Date.now()-entroTT} ms`)
                eventBus().emitter.emit('memory-data', {
                        eans_pdv: eans_pdv.data, ubicaciones_pdv: ubicaciones_pdv.data, 
                        catalogo_pdv: catalogo_pdv.data, lotes_pdv :lotes_pdv.data , 
                        pdv: self.$pdv , nompos: self.$nompos , 
                        ubiku_recepcion: self.$ubiku_recepcion , 
                        pdv_geoloc : self.$pdv_geoloc, 
                        bobinas_pdv : bobinas_pdv.data, 
                        codigos_bobinas : codigos_bobinas.data,  
                        clientes_pdv : clientes_pdv.data, 
                        isIOS : self.$isIOS , logged: true, 
                        ubiku_rol : self.$ubiku_rol, 
                        mis_pdvs : self.$mis_pdvs

                    })
                self.$logged = true;
                try {
                    await self.$firebase.auth().signOut()
                    window.fake_console('consigo el logout de firebase')
                    }
                catch  {
                    window.fake_console('imposible el logout ')
                }
                window.fake_console(self.$deeplink , self.$home)
                const destino =  ( self.$deeplink !=undefined && self.$deeplink != '/login') ? self.$deeplink : self.$home
                window.fake_console(destino)
                const hasParams = destino.indexOf('?')!=-1
                window.fake_console(`Salgo funcion persist -sin mandar a login-, he tardado ${Date.now()-entroTT} ms, voy a ${destino}`)
                const u = `${destino}${hasParams ? '&' : '?'}q=${Date.now()}`
                window.fake_console(u)
                document.getElementById("google-login-btn").setAttribute("disabled", "false");
                document.getElementById("microsoft-login-btn").setAttribute("disabled", "false");
                self.$router.replace(u)   
            }
        catch (ex) 
            {
                console.log('L338 main: entro en el caso login', ex)
                window.fake_console(`Salgo funcion persist -enviando a LOGIN-, he tardado ${new Date()-entroTT} seg`)
                document.getElementById("google-login-btn").setAttribute("disabled", "false");
                document.getElementById("microsoft-login-btn").setAttribute("disabled", "false");
                self.$router.replace('/login')
            }    
    };
    
/**  Cargamos las variables de entorno
 * Se hacia con cada navegacion!!!!!
 * Lo desplazamos a app.create o cambios en login 
*/
const procesoInicial = async (desde) => {
    window.fake_console('DESDE DONDE :', desde)
    const d = new Date()
    window.fake_console(`${d} : Inicio Proceso Inicial desde ${desde}`)
    const res = await Device.getId();
    self.$deviceUuid = res.uuid;
    const info = await Device.getInfo();
    self.$isIOS = (info.operatingSystem||'').toLocaleLowerCase() === 'ios'
    const be = await loadStorage('sales-plus');
    self.$pdv = await loadStorage('pdv');
    self.$nompos = await loadStorage('nompos');
    self.$ubiku_recepcion = await loadStorage('ubiku_recepcion');
    self.$pdv_geoloc = await loadStorage('pdv_geoloc');
    self.$current_user = await loadStorage('ml');
    self.$current_user_name = await loadStorage('uname');
    self.$geofence = await loadStorage('geofence');
    self.$ubiku_rol = await loadStorage('ubiku_rol');
    self.$mis_pdvs = await loadStorage('mis_pdvs');
    self.$wnumber = await loadStorage('wn');
    window.fake_console(self.$pdv, self.$isIOS)
    eventBus().emitter.emit('deeplink-log', { pdv: self.$pdv   ,  isIOS : self.$isIOS , 
            logged: self.$logged, nompos: self.$nompos , 
            ubiku_recepcion: self.$ubiku_recepcion, 
            pdv_geoloc : self.$pdv_geoloc   
        })
    await interceptores(self, be) 
    const chequer = await self.$http.post(`/check/${self.$pdv}`)
    window.fake_console(chequer)
    if (chequer) {
        const d = chequer.data
        if (d.racrol === 0 && self.$pdv !=d.pdv_principal){
            window.fake_console({racrol: d.racrol, pdv: self.$pdv, pdv_principal : d.pdv_principal })
            window.fake_console({name : 'login',  params : { forzar_pdv : d.pdv_principal }})
            self.$router.replace({name : 'login',  params : { forzar_pdv : d.pdv_principal }})
            
        }
        else {
            console.log('Main 374 : ', 'estoy "persitiendo"')
            persist(self, be)    
        }
    }
}

export {procesoInicial};

/*procesoInicial('MAIN')*/


window.addEventListener("load", function() {
    /** Intento anular boton */
    document.addEventListener("backbutton", function(){}, false);

    self.$NoBackNavigator = function () {
        history.pushState(null, document.title, location.href);
        window.addEventListener('popstate', function ()
            { history.pushState(null, document.title, location.href); }); 
    }
  });

  

router.isReady().then(async () => {
    await procesoInicial('New MAIN');
    window.fake_console('dentro del router ready')
    app.mount('#app');  
    window.fake_console('main ','App montada')
    defineCustomElements(window);
    const permisos = await Camera.checkPermissions()
    const permisosGeo = await Geolocation.checkPermissions()
    //window.fake_console(permisos)
    if (!permisos) {await Camera.requestPermissions('camera')}
  
    if (!permisosGeo) {await Geolocation.requestPermissions('camera')}



    
});
 

/** Variables globales que se utilizan en el comportamiento de la app */
eventBus().emitter.on('user-logged',async (roles, pdv_actual, nompos_actual , ubiku_recepcion, pdv_geoloc)=>{ 
  window.fake_console('en la funcion emit.on de user-logged')
  window.fake_console(roles)
  //window.fake_console(roles)
  self.$current_user = roles.usr
  self.$current_user_name = roles.uname
  self.$geofence = roles.geofence
  self.$ubiku_rol = roles.ubiku_rol 
  self.$mis_pdvs = roles.pdv||'000'
  self.$pdv = pdv_actual 
  self.$ubiku_recepcion = ubiku_recepcion 
  self.$pdv_geoloc = pdv_geoloc 
  self.$nompos = nompos_actual
  self.$wnumber = roles.wnumber
  procesoInicial('Login')
});

