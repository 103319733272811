    import { alertController } from '@ionic/vue';
    
    import { saveStorage } from '@/utilities/storage/storage-utilities-main'
    import { pick, chain } from 'underscore';
    import { esUbicacion } from '@/utilities/global-constants';
    import {  ValidarDNI }  from '@/utilities/global_functions'
    import { entregaDistance, entregaPdvDistance } from '@/utilities/location';
    /*
    import { takeDeliveryPhoto} from '@/pages/enviar/components/image-utilities.js' ;
    import { IonicSafeString } from '@ionic/core';
    */

    import { pluck } from 'underscore';

    
    const consola = function(){ void(0) }; //consola    

    const recuperarGoogleGeoLocEntrega = async function (myhttp , exp , forzar) {
      void(myhttp)
      try {
        consola(exp)  
        let { codemp, codcli, domenv, cpenv, nomenv, pobenv } = exp
        const payload = { codemp, codcli, domenv, cpenv, nomenv, pobenv, forzar }
          const d = await myhttp.post( `/get_delivery_pointer/${codemp}`, payload)
          const direcciones = d.data.results
          consola(direcciones)
          const unico = chain(direcciones).filter(x => x.formatted_address.indexOf(exp.cpenv)!=-1)
              .map(y => {
                  const l = pick(y, ['geometry', 'formatted_address']);
                  const {lat, lng }= l.geometry.location 
                  consola(`${lat} , ${lng}`)
                  const { formatted_address } = l 
                  return { lat_env: lat, lon_env :lng, formatted_address }
              }).value()
          if (unico && unico.length==1 ) {
            const {lat_env, lon_env, formatted_address}  = unico[0]
            if (d.data.source=='google')
              {
                const d = await myhttp.post( `/set_delivery_pointer/${codemp}`, {lat_env, lon_env, formatted_address, ...payload})
                consola(d.data)
              }            
            return { lat_env, lon_env }
          }
          else {
              const posibles = chain(direcciones).map(y => {
                const l = pick(y, ['geometry', 'formatted_address']);
                const {lat, lng }= l.geometry.location 
                consola(`${lat} , ${lng}`)
                const { formatted_address } = l 
                return { lat_env: lat, lon_env :lng, formatted_address }
            }).value()
            window.fake_console(posibles)
            const elegido = await openAlertaGeoLoc(posibles, exp, forzar)
            if (elegido && elegido !=false && elegido !='forzar')
            { 
              await myhttp.post( `/set_delivery_pointer/${codemp}`, {...elegido , ...payload})
            }
            return elegido
          }

      }
      catch (ex) {
          console.log(ex)
          return false 
      }
  }




    const openAlertaRutas = async function (rutas_pdv){
        const mis_inputs = rutas_pdv.map( (v) => {
            const Etiqueta = `${v.deszon}`
                    return {'label' : Etiqueta, 'type' :'checkbox', 'value' : v}
        })
        const head = `Selecciona las rutas`
        const msg = `Marca las rutas que quieres filtrar`
        const alerta = await alertController
            .create({
                header: head, 
                message: msg,
                mode : "md",
                cssClass: 'custom-rutas-alert',
                backdropDismiss: false, 
                inputs :mis_inputs, 
                buttons: [
                    {
                        text: 'Todas',
                        role: 'all',
                        handler: () => {
                          consola('Alert canceled');
                        },
                      },                    {
                      text: 'Cancelar',
                      role: 'cancel',
                      handler: () => {
                        consola('Alert canceled');
                      },
                    },
                    {
                      text: 'OK',   
                      role: 'confirm',
                      handler: ( ) => {
                        consola('Alert confirmed');
                      },
                    },
                  ]
            })
        await alerta.present();
        
        const {data, role} = await alerta.onDidDismiss();
        consola(data)
        if (role =='all') {
            return {'deszon': 'all'}
        }
        else if (role=='confirm'){
            consola(data)
            return data 
        }
        else {
           return undefined
        }
    }

        

    const openAlertaVehiculo = async function (vehiculos_pdv, actual){

        window.fake_console(vehiculos_pdv, actual   )
        const mis_inputs = vehiculos_pdv.filter(y=>y.activo === 1 ).map( (v) => {
                const Etiqueta = `${v.matricula} - ${v.modelo}`
                return {'label' : Etiqueta, 'type' :'radio', 'value' : v , 'checked' :v.matricula == actual }
        })
        const head = `Selecciona un vehiculo`
        const msg = `No nos consta ningun vehiculo, debes elegir uno para poder continuar`
        const alerta = await alertController
            .create({
                header: head, 
                message: msg,
                mode : "md",
                cssClass: 'custom-rutas-alert',
                backdropDismiss: false, 
                inputs :mis_inputs, 
                buttons: [  
                    {
                      text: 'Cancelar',
                      role: 'cancel',
                      handler: () => {
                        consola('Alert canceled');
                      },
                    },
                    {
                      text: 'Seleccionar',   
                      role: 'confirm',
                      handler: ( ) => {
                        consola('Alert confirmed');
                      },
                    },
                  ]
            })
        await alerta.present();
        
        const {data, role} = await alerta.onDidDismiss();
        consola(data)
        if (role=='confirm'){
            consola(data)
            if (data.values) {
              await saveStorage('vehiculo', data.values.matricula)
              return data.values.matricula
            } else {
              return undefined
            }
        }
        else {
           return undefined
        }
    }

        


    const openAlertaGeoLoc = async function (direcciones, dat_env, forzar){
      const mis_inputs = direcciones.map( (v) => {
              const Etiqueta = `${v.formatted_address}`
              return {'label' : Etiqueta, 'type' :'radio', 'value' : v  }
      })
      let head = `Selecciona la direccion correcta`
      let msg = `Por favor, confirma la dirección de entrega. En el albarán figura la siguiente: 
              <div id="direccion_entrega_en_albaran">
                  <b>${dat_env.nomenv}</b></br/>
                  ${dat_env.domenv}<br/>
                  ${dat_env.cpenv} ${dat_env.pobenv} 
              </div>
              Si cancelas sin elegir, cerraremos sin crear el envio y te redirigemos a la lista de envios. 
        `
      if ((!mis_inputs || mis_inputs.length ==0) && !forzar){
        head = "Direccion no localizada!"
        
        msg=`
          <div id="direccion_entrega_en_albaran">
            <b> ${dat_env.nomenv}</b></br/>
                ${dat_env.domenv}<br/>
                ${dat_env.cpenv} ${dat_env.pobenv} 
          </div>
          <div id="error_direccion_albaran">
              La dirección detallada en el albarán no ha sido localizada en Google. <br/>
              Para proseguir elige entre: <br/>
              <ul>
                <li><b> DESCARTAR:</b><br/>
                  Revisad en Gestión2000 la dirección de entrega y corregidla junto con la del albarán.<br/>
                  El envio no se creará ahora, hazlo una vez corregido en Gestión2000. 
                </li>
                <br/>
                <li><b> FORZAR:</b><br/>
                  Si es urgente o la dirección es correcta, fuerza el codigo postal, asignaremos como pointer el del codigo postal.
                </li>
              </ul>
              Si fuerzas, recuerda confirmar al entregar las coordenadas correctas, así estarán bien para futuras entregas. 
          </div>`
        
      }
      if ((!mis_inputs || mis_inputs.length ==0) && forzar === true){
        head = "Direccion no localizada!"
        
        msg=`
          <div id="direccion_entrega_en_albaran">
            <b> ${dat_env.nomenv}</b></br/>
                ${dat_env.domenv}<br/>
                ${dat_env.cpenv} ${dat_env.pobenv} 
          </div>
          <div id="error_direccion_albaran">
              La dirección detallada en el albarán no ha sido localizada en Google tras intentar forzarla. <br/>
              Para proseguir, revisad en Gestión2000 la dirección de entrega y corregidla junto con la del albarán.<br/>
              El envio no se creará ahora, hazlo una vez corregido en Gestión2000.  
          </div>`
        
      }


      const botones = [  
        {
          text: 'Cancelar',
          role: 'cancel',
          visible : mis_inputs && mis_inputs.length >0 , 
          handler: () => {
            consola('Alert canceled');
          },
        },
        {
          text: 'Seleccionar',   
          visible : mis_inputs && mis_inputs.length >0 , 
          role: 'confirm',
          handler: ( data ) => {
            window.fake_console(data)
            if (!data) {return false}
            consola('Alert confirmed');
          },
        }, 
        {
          text: 'Forzar',   
          visible : (!mis_inputs || mis_inputs.length == 0) && !forzar , 
          role: 'force',
          handler: ( ) => {
            consola('Alert confirmed');
          }
        },
        {
          text: 'Descartar',   
          visible : !mis_inputs || mis_inputs.length == 0 , 
          role: 'discard',
          handler: ( ) => {
            consola('Alert confirmed');
          },
        }
      ]
      const alerta = await alertController
          .create({
              header: head, 
              message: msg,
              mode : "md",
              cssClass: 'custom-rutas-alert',
              backdropDismiss: false, 
              inputs :mis_inputs, 
              buttons: botones.filter(yy => yy.visible === true)
          })
      await alerta.present();
      
      const {data, role} = await alerta.onDidDismiss();
      consola(data)
      
      if (role=='confirm'){
          consola(data)
          return data.values
      }
      else if (role == 'force') {
        return 'forzar'
      }
      else if (role=='discard') {
        return false 
      } 
      else {
         return false
      }
  }


  /** alerta para la colocación en espera de un envio  */

      const openAlertaPonerEnEspera = async function (envio){
        const head = `Poner en espera un envio`
        const msg = `Para poner en espera el envio ${envio.ms_uuid}  del cliente ${envio.nomcli} debes leer la ubicación donde lo dejarás y confirmar.`
        const alerta = await alertController
            .create({
                header: head, 
                message: msg,
                mode : "md",
                cssClass: 'custom-ptl-alert',
                backdropDismiss: false, 
                inputs :[
                  {
                    type : "textarea", 
                    name: "ubi", 
                    id: "id_ubicacion_espera", 
                    tabindex: 0 , 
                    placeholder: 'Lee la ubicación de espera',
                  }, 
                  {
                    value : '', 
                    id : 'id_control_error_ubicacion_espera', 
                    disabled: true,
                    cssClass : 'etiqueta-error'
                  },
                ],
                buttons: [
                    {
                      text: 'Cancelar',
                      role: 'cancel',
                    },
                    {
                      text: 'OK',   
                      id: "confirmar_ubi_espera", 
                      role: 'confirm',
                      handler: ( data) => {
                        consola(data)
                        data.ubi = data.ubi.replace(/\n/g,'')
                        if (esUbicacion.test(data.ubi)== true) {
                          return true 
                        }
                        else {
                          control_error.value = 'El texto no corresponde es una ubicacion'
                          foco.value = ''; 
                          return false 
                        }
                      },
                    },
                  ]
            })
        await alerta.present();
        var foco = document.getElementById('id_ubicacion_espera')       
        foco.focus()
        foco.onkeyup = async (e) =>{
          if (e.keyCode==13) {
            const ubi = foco.value.replace(/\n/g,'')
            consola(ubi,   esUbicacion.test(ubi))
            if (esUbicacion.test(ubi) ) {
            await alerta.dismiss(ubi, 'confirm')
            }
            consola(ubi.replace(/\n/g,''))
          }
        }
        let control_error = document.getElementById('id_control_error_ubicacion_espera')       

        const {data, role} = await alerta.onDidDismiss();
        consola(data, role)
        if (role=='confirm' ){
          consola('en el true')
          return data 
        }
        else {
            consola('en el falso')
            return false
        }
        
    }



    const openAlertaDescargaCamion = async function (envio){
      const head = `Descarga ${envio.ms_uuid}  `
      const tags = [...Array(envio.bultos).keys()].map( (x)=>
          { return `<li id="chk-${x+1}" class="no"> Bulto ${x+1} de ${envio.bultos}</li>`}
        ).join('')

      let checks = [...Array(envio.bultos).keys()].map(()=>false)

      const msg = `
            <input id="dummy_blt" />
            Para descargar y devolver a la balda de ruta el envio del cliente ${envio.nomcli} 
            debes leer todos los bultos y confirmar.<br/>

            <ul class="en-alerta">${tags}</ul>
              `
      
      const alerta = await alertController
          .create({
              header: head, 
              message: msg,
              mode : "md",
              cssClass: 'custom-rutas-alert',
              backdropDismiss: false, 
              keyboardClose :true, 
              inputs : [        {
                type :"textarea", 
                value : '', 
                name : "error_blt",
                id : 'id_blt_error_box', 
                disabled: true,
                cssClass : 'etiqueta-error grande'
              }
            ],
              buttons: [
                  {
                    text: 'Cancelar',
                    role: 'cancel',
                  },
                  {
                    text: 'Confirmar',   
                    role: 'confirm',
                    handler: ( data) => {
                      consola(data, checks)
                      if (checks.indexOf(false)!=-1){
                        let control_error = document.getElementById('id_blt_error_box')       
                        control_error.value = 'Bultos pendientes de confirmar'
                        return false 
                      }
                      else  {
                        return true 
                      }
                    },
                  },  
                ]
          })
      await alerta.present();
      var foco = document.getElementById('dummy_blt')       
      foco.focus()
      foco.onblur = ()=> {foco.focus()}
      foco.onkeyup = async (e) =>{
        if (e.keyCode==13) {
          const bulto = foco.value.replace(/\n/g,'')
          try {
            const env = JSON.parse(bulto)
            consola(env)
            if(env.ms_uuid  != envio.ms_uuid) { 
              consola('envio distinto')
              throw 'Envio distinto'
            }
            else {
              const x = env.bl
              checks[x-1]= true 
              let ele = document.getElementById(`chk-${x}`)
              ele.classList.remove("no");
              ele.classList.add("si");
            }
          }
          catch(ex)
          {
            let control_error = document.getElementById('id_blt_error_box')       
            control_error.value = ex
          }
         consola(bulto)
          foco.value = ''
        }        
      } 
      const { role} = await alerta.onDidDismiss();
      if (role=='confirm' ){
        consola('en el true')
        return true 
      }
      else {
          consola('en el falso')
          return undefined
      }
      
  }

 

  
  const openAlertaIncidencia = async function (envio){
    const head = `Incidencia de entrega`
    const msg = `<b>Envio ${envio.ms_uuid}</b><br/> 
                Para generar una incidencia de entrega del cliente ${envio.nomcli},
                detalla a continuación el motivo de la misma, 
    `
    const alerta = await alertController
        .create({
            header: head, 
            message: msg,
            mode : "md",
            cssClass: 'custom-rutas-alert',
            backdropDismiss: false, 
            inputs :[            {
              name: 'motivo',
              type: 'textarea',
              placeholder: 'Detalle el motivo de la incidencia',
            },], 
            buttons: [  
                {
                  text: 'Cancelar',
                  role: 'cancel'                
                },
                {
                  text: 'Crear incidencia',   
                  role: 'confirm', 
                  handler(data) {
                    if (!data.motivo ||data.motivo.length ==0) {
                      let control_error = document.getElementById('id_blt_error_box')       
                      control_error.value = 'Faltan campos obligatorios '
                      return false 

                    }
                  }
                },
              ]
        })
    await alerta.present();
    const {data, role} = await alerta.onDidDismiss();
    consola(data)
    if (role=='confirm'){
        return data.values.motivo
    }
    else {
       return undefined
    }
}


const openAlertaEntrega = async function (myhttp, envio , pdv_geoloc){
  const {lat_env, lon_env} = envio
  let cerca 
  if( envio.envio_tipo == 'REPARTO') {
      cerca = await entregaDistance([lat_env, lon_env])
    }
  else 
    {
      cerca = await entregaPdvDistance([pdv_geoloc[0], pdv_geoloc[1]])
    }
  
  window.fake_console(cerca)
  if (!cerca) { return }
  if (cerca && cerca !=true && cerca.localizacion!='pdv') {
      const {latitude, longitude} = cerca; 
      const { codemp, codcli, domenv, cpenv, pobenv } = envio 
      const payload = { codemp, codcli, domenv, cpenv, pobenv , lat_env :latitude, lon_env : longitude }
      try { await myhttp.post(`/set_delivery_pointer/${codemp}`, payload) }
      catch { alert('error !!!!! L464 expedir-utilities')}
      }


      const mis_inputs = [            
        {
          name: 'bultos',
          type: 'number',
          placeholder: 'Bultos entrega *',
          caso : ['REPARTO','RECOGIDA','AGENCIA_GES','AGENCIA_CLIENTE' ]
        },
        {
          name: 'nombre',
          type: 'text',
          required : true, 
          placeholder: 'Nombre del receptor *',
          caso : ['REPARTO','RECOGIDA','AGENCIA_GES','AGENCIA_CLIENTE' ]
        },
        {
          name: 'nif',
          type: 'text',
          placeholder: 'Nif del receptor',
          caso : ['REPARTO','RECOGIDA','AGENCIA_GES','AGENCIA_CLIENTE' ]
        },
        {
          name: 'agencia',
          type: 'text',
          required : true, 
          placeholder: 'Agencia',
          caso : ['AGENCIA_GES','AGENCIA_CLIENTE' ]
        },
        {
          name: 'codexp_agencia',
          type: 'text',
          required : true, 
          placeholder: 'Codigo expedicion de la agencia',
          caso : ['AGENCIA_GES','AGENCIA_CLIENTE' ]
        },
        {
          type :"textarea", 
          value : '',  
          name : "error_blt",
          id : 'id_blt_error_box', 
          disabled: true,
          cssClass : 'etiqueta-error mediano'
        }
      
      ]
      const inputs = mis_inputs.filter(x =>x.id =='id_blt_error_box' || x.caso.indexOf(envio.envio_tipo)!=-1)

      const head = `Reportar entrega` 
      const msg = `<b>Envio ${envio.ms_uuid}</b><br/> 
                  Vas a realizar la entrega. Por favor rellena la información requerida. 

                  
      `
      /**Si quieres guardar una imagen, clicka en 'Imagen' antes de confirmar.  */
      const alerta = await alertController
          .create({
              header: head, 
              message: msg,
              mode : "md",
              cssClass: 'custom-rutas-alert',
              backdropDismiss: false, 
              inputs : inputs, 
              buttons: [  
                {
                  text: 'Cancelar',
                  role: 'cancel'                
                },
                {
                  text: 'Grabar Entrega',   
                  role: 'confirm', 
                  handler(data) {
                    const nif = data.nif.toUpperCase().replace(/[^A-Z0-9]/g,'')
                    consola(nif)
                    const nif_valido = ValidarDNI(nif)
                    let control_error = document.getElementById('id_blt_error_box')       
                    if (!data.bultos || parseInt(data.bultos) != envio.bultos) {
                      control_error.value = 'Numero de bultos incorrecto, revisa'
                      return false 
                    }
                    else if (!nif_valido ||nif_valido===false) {
                      control_error.value = 'Nif no informado o invalido. En breve no será posible entregar sin facilitar nif. Si quieres evitar este bloqueo apreta "NO FACILITA NIF"'
                      return false              
                    }
                    else { 
                      const requeridos = pluck(inputs.filter(x=>x.required===true),'name')
                      const validacion = requeridos.reduce( (m,v)=> {
                        return m+= (!data[v]||data[v].length==0) ? 1 : 0 
                      },0)
                      consola(requeridos, validacion )
                      if (validacion !=0) {
                        control_error.value = 'Faltan campos obligatorios '
                        return false
                      }
                      data.has_nif = 1; 
                      return true
                    }
                  }
                },            
                {
                  text: 'NO FACILITA NIF',   
                  role: 'no_nif', 
                  handler(data) {
                      let control_error = document.getElementById('id_blt_error_box')       
                      if (!data.bultos || parseInt(data.bultos) != envio.bultos) {
                        control_error.value = 'Numero de bultos incorrecto, revisa'
                        return false 
                      }
                      else { 
                        const requeridos = pluck(inputs.filter(x=>x.required===true),'name')
                        const validacion = requeridos.reduce( (m,v)=> {
                          return m+= (!data[v]||data[v].length==0) ? 1 : 0 
                        },0)
                        consola(requeridos, validacion )
                        if (validacion !=0) {
                          control_error.value = 'Faltan campos obligatorios '
                          return false
                        }
                        data.has_nif = 0 
                        return true
                      }
                  }
                },
                /*
                {
                  text: 'Foto entrega',   
                  role: 'photo', 
                  async handler() {
                    await takeDeliveryPhoto(myhttp, envio)
                    return false
                  }
                },*/

                ]
          })
      await alerta.present();
      const {data, role} = await alerta.onDidDismiss();
      consola(data)
      if (role=='confirm'|| role =='no_nif'){
          return data.values
      }
      else {
        return undefined
      }
    }

    // envio por envios??
const openAlertaEntregasAgrupadas = async function (myhttp, envios, pdv_geoloc, bultos) {

  envios.map(async envio => {
    const { lat_env, lon_env } = envio;
    let cerca;
    if (envio.envio_tipo == "REPARTO") {
      cerca = await entregaDistance([lat_env, lon_env]);
    } else {
      cerca = await entregaPdvDistance([pdv_geoloc[0], pdv_geoloc[1]]);
    }

    window.fake_console(cerca);

    if (!cerca) {
      return;
    }

    if (cerca && cerca != true && cerca.localizacion != "pdv") {
      const { latitude, longitude } = cerca;
      const { codemp, codcli, domenv, cpenv, pobenv } = envio;
      const payload = {
        codemp,
        codcli,
        domenv,
        cpenv,
        pobenv,
        lat_env: latitude,
        lon_env: longitude,
      };
      try {
        await myhttp.post(`/set_delivery_pointer/${codemp}`, payload);
      } catch {
        alert("error !!!!! L464 expedir-utilities");
      }
    }
  })
  

  const mis_inputs = [
    {
      name: "bultos",
      type: "number",
      placeholder: "Total bultos entregas *",
      caso: ["REPARTO", "RECOGIDA", "AGENCIA_GES", "AGENCIA_CLIENTE"],
    },
    {
      name: "nombre",
      type: "text",
      required: true,
      placeholder: "Nombre del receptor *",
      caso: ["REPARTO", "RECOGIDA", "AGENCIA_GES", "AGENCIA_CLIENTE"],
    },
    {
      name: "nif",
      type: "text",
      placeholder: "Nif del receptor",
      caso: ["REPARTO", "RECOGIDA", "AGENCIA_GES", "AGENCIA_CLIENTE"],
    },
    {
      name: "agencia",
      type: "text",
      required: true,
      placeholder: "Agencia",
      caso: ["AGENCIA_GES", "AGENCIA_CLIENTE"],
    },
    {
      name: "codexp_agencia",
      type: "text",
      required: true,
      placeholder: "Codigo expedicion de la agencia",
      caso: ["AGENCIA_GES", "AGENCIA_CLIENTE"],
    },
    {
      type: "textarea",
      value: "",
      name: "error_blt",
      id: "id_blt_error_box",
      disabled: true,
      cssClass: "etiqueta-error",
    },
  ];
  const inputs = mis_inputs.filter(
    (x) => x.id == "id_blt_error_box" || x.caso.some(r => pluck(envios, 'envio_tipo').includes(r))
  );

  const head = `Reportar entregas`;
  const msg = `Envios<b><br/><br/>${envios.map(envio => ' - ' + envio.ms_uuid).join('<br>')}</b><br/><br/> 
                Vas a realizar la entrega. Por favor rellena la información requerida.`;


  /**Si quieres guardar una imagen, clicka en 'Imagen' antes de confirmar.  */
  const alerta = await alertController.create({
    header: head,
    message: msg,
    mode: "md",
    cssClass: "custom-rutas-alert",
    backdropDismiss: false,
    inputs: inputs,
    buttons: [
      {
        text: "Cancelar",
        role: "cancel",
      },
      {
        text: "Grabar Entrega",
        role: "confirm",
        handler(data) {
          const nif = data.nif.toUpperCase().replace(/[^A-Z0-9]/g, "");
          consola(nif);
          const nif_valido = ValidarDNI(nif);
          let control_error = document.getElementById("id_blt_error_box");
          if (!data.bultos || parseInt(data.bultos) != bultos) {
            control_error.value = "Numero de bultos incorrecto, revisa";
            return false;
          } else if (!nif_valido || nif_valido === false) {
            control_error.value =
              'Nif no informado o invalido. En breve no será posible entregar sin facilitar nif. Si quieres evitar este bloqueo apreta "NO FACILITA NIF"';
            return false;
          } else {
            const requeridos = pluck(
              inputs.filter((x) => x.required === true),
              "name"
            );
            const validacion = requeridos.reduce((m, v) => {
              return (m += !data[v] || data[v].length == 0 ? 1 : 0);
            }, 0);
            consola(requeridos, validacion);
            if (validacion != 0) {
              control_error.value = "Faltan campos obligatorios ";
              return false;
            }
            data.has_nif = 1;
            return true;
          }
        },
      },
      {
        text: "NO FACILITA NIF",
        role: "no_nif",
        handler(data) {
          let control_error = document.getElementById("id_blt_error_box");
          if (!data.bultos || parseInt(data.bultos) != bultos) {
            control_error.value = "Numero de bultos incorrecto, revisa";
            return false;
          } else {
            const requeridos = pluck(
              inputs.filter((x) => x.required === true),
              "name"
            );
            const validacion = requeridos.reduce((m, v) => {
              return (m += !data[v] || data[v].length == 0 ? 1 : 0);
            }, 0);
            consola(requeridos, validacion);
            if (validacion != 0) {
              control_error.value = "Faltan campos obligatorios ";
              return false;
            }
            data.has_nif = 0;
            return true;
          }
        },
      }
    ],
  });

  document
    .querySelector(".alert-button-group-vertical")
    .classList.remove("alert-button-group-vertical");

  await alerta.present();

  const { data, role } = await alerta.onDidDismiss();
  consola(data);
  if (role == "confirm" || role == "no_nif") {
    return data.values;
  } else {
    return undefined;
  }
};


export {
  openAlertaRutas,
  recuperarGoogleGeoLocEntrega,
  openAlertaGeoLoc,
  openAlertaVehiculo,
  openAlertaPonerEnEspera,
  openAlertaDescargaCamion,
  openAlertaIncidencia,
  openAlertaEntrega,
  openAlertaEntregasAgrupadas,
};

