import eventBus from "@/event-bus";
import { loadStorage, saveMainStorage } from "@/utilities/storage/storage-utilities-main";
import { openAlertaV2 } from "@/utilities/services/alert-utilities";
const consola = function () {
  void 0;
};

const LoginWithGoogle = async function (self) {
  try {
    await self.$firebase.auth().setPersistence(self.$firebase.auth.Auth.Persistence.LOCAL);
    const provider = new self.$firebase.auth.GoogleAuthProvider();

    self.$http.defaults.headers["provider"] = "google";

    //window.fake_console('estoy en provider')
    provider.setCustomParameters({ prompt: "select_account" });
    //window.fake_console('he fijado google como proveedor y el prompt ', provider)
    await self.$firebase.auth().signInWithPopup(provider);
    const t = await self.$firebase.auth().currentUser.getIdToken(true);
    consola(t);
    /** Cambiamos solo el header de esta peticion  */
    self.$http.defaults.headers["Authorization"] = "Bearer " + t;
    const supRequest = await self.$http.post("/login", { dev: self.$deviceUuid, provider: 'google' });
    const roles = supRequest.data;

    await Login(roles);
  } catch (error) {
    window.fake_console("EN EL ERROR DE LOGIN");
    window.fake_console(error);
    consola(error);
    window.miconsola("Login 73 (Error) : ", JSON.stringify(error));
  }
};

const LoginWithMicrosoft = async (self) => {
  const provider = new self.$firebase.auth.OAuthProvider("microsoft.com");
  self.$http.defaults.headers["provider"] = 'microsoft';

  self.$firebase
    .auth()
    .setPersistence(self.$firebase.auth.Auth.Persistence.LOCAL)
    .then(function () {
      // Con consent, entra directamente con el usuario con el que has iniciado sesion,
      // Pero con login, hay siempre inicio de sesion sin recordar el usuario
      provider.setCustomParameters({
        prompt: "select_account",
        tenant: "45de56ea-e505-43a5-bd60-4957c8afc438",
      });

      self.$firebase
        .auth()
        .signInWithPopup(provider)
        .then((usr) => {
          /** Hay usuario, obtenemos token y fijamos rol y datos del usuario  */
          window.fake_console("Login 48: " + JSON.stringify(usr.user.email));

          self.$firebase
            .auth()
            .currentUser.getIdTokenResult(true)
            .then(async (idTokenResult) => {
              self.$http.defaults.headers["Authorization"] = "Bearer " + idTokenResult.token;

              const supRequest = await self.$http.post("/login", { dev: self.$deviceUuid, provider: 'microsoft' });
              const roles = supRequest.data;

              await Login(roles);
            })
            .catch((err) => {
              alert("en el login " + JSON.stringify(err.message));
            });
        })
        .catch((err) => {
          alert("en el login " + JSON.stringify(err.message));
        });
    })
    .catch(function (error) {
      window.fake_console("EN EL ERROR DE LOGIN");
      window.fake_console(error);
      consola(error);
      window.miconsola("Login 73 (Error) : ", JSON.stringify(error));
    });
};

const Login = async (roles) => {
  const ispdv = new RegExp(/[0-9]{3,4}/g);
  window.fake_console(roles);
  await saveMainStorage(roles.tk, roles.usr, roles.uname, roles.impresoras, roles.geofence, roles.ubiku_rol, roles.pdv || "000", roles.wnumber);
  const pdv_actual = await loadStorage("pdv");
  const nompos_actual = await loadStorage("nompos");
  const ubiku_recepcion = await loadStorage("ubiku_recepcion");
  const pdv_geoloc = await loadStorage("pdv_geoloc");
  consola(pdv_actual);
  /* chequeo pdv ok  */
  /* consola(pdv_actual, roles.pdv, ispdv.test(pdv_actual) , roles.pdv !=null ? roles.pdv.indexOf(pdv_actual!=-1) : true )
            window.fake_console(pdv_actual, roles, ispdv.test(pdv_actual) , roles.pdv.indexOf(pdv_actual))*/
  const pdvs = roles.tipo === "all" ? true : !roles.pdv ? false : roles.pdv.indexOf(pdv_actual) !== -1;

  window.fake_console(pdv_actual);
  window.fake_console(roles);
  window.fake_console(pdvs);

  if (ispdv.test(pdv_actual) === true && pdvs) {
    window.fake_console("por todos los pdvs ");
    eventBus().emitter.emit("user-logged", roles, pdv_actual, nompos_actual, ubiku_recepcion, pdv_geoloc);
  }
  //else if ( ispdv.test(pdv_actual) === true && roles.pdv.indexOf(pdv_actual) !== -1 )
  else if (ispdv.test(pdv_actual) === true && pdvs) {
    window.fake_console("por pdv concreto ");
    eventBus().emitter.emit("user-logged", roles, pdv_actual, nompos_actual, ubiku_recepcion, pdv_geoloc);
  } else {
    window.fake_console(pdv_actual, roles, pdvs, ispdv.test(pdv_actual));
    window.fake_console("otra situacion ");
    await openAlertaV2("Pdv erroneo", "Pdv no seleccionado o no válido para tus permisos");
  }
};

export { LoginWithGoogle, LoginWithMicrosoft };
